import {
  ArrowLeftOnRectangleIcon,
  FolderIcon,
  HomeIcon,
  BuildingStorefrontIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { NavLink, useNavigate } from "react-router-dom";
import frateLogo from "../../../assets/images/frate_logo.svg";
import { clearAuthToken } from "../../../services/auth";

const navigation = [
  { name: "Dashboard", icon: HomeIcon, to: "/" },
  { name: "GraphiQL", icon: FolderIcon, to: "/graphiql" },
  { name: "Shops", icon: BuildingStorefrontIcon, to: "/shops" },
  { name: "Tools", icon: WrenchIcon, to: "/tools" },
];

export const SideNav = () => {
  const navigate = useNavigate();
  return (
    <div className="w-60 min-h-[100vh] h-full flex-none">
      <div className="min-h-[100vh] flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5 pb-4">
        <div className="flex flex-shrink-0 items-center px-4">
          <img className="h-8 w-auto" src={frateLogo} alt="Frate logo" />
        </div>
        <div className="mt-5 flex flex-grow flex-col">
          <nav
            className="flex-1 flex flex-col bg-white px-2"
            aria-label="Sidebar"
          >
            {navigation.map(item => (
              <NavLink
                key={item.name}
                to={item.to}
                className={({ isActive }) => {
                  return clsx(
                    isActive
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md mb-1",
                  );
                }}
              >
                {({ isActive }) => {
                  return (
                    <>
                      <item.icon
                        className={clsx(
                          isActive
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6",
                        )}
                        aria-hidden="true"
                      />
                      <span className="flex-1">{item.name}</span>
                      {/* {item.count ? (
                        <span
                          className={clsx(
                            isActive
                              ? "bg-white"
                              : "bg-gray-100 group-hover:bg-gray-200",
                            "ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full",
                          )}
                        >
                          {item.count}
                        </span>
                      ) : null} */}
                    </>
                  );
                }}
              </NavLink>
            ))}

            <button
              className={clsx(
                "mt-auto",
                "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
              )}
              onClick={() => {
                clearAuthToken();
                setTimeout(() => {
                  navigate("/login");
                });
              }}
            >
              <ArrowLeftOnRectangleIcon
                className={clsx(
                  "text-gray-400 group-hover:text-gray-500",
                  "mr-3 flex-shrink-0 h-6 w-6",
                )}
                aria-hidden="true"
              />
              <span className="flex-1 text-left">Logout</span>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

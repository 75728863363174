import { useQuery } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { gql } from "graphql-request";
import { getGQLClient } from "../../services/graphql";
import { Return } from "../../types/graphql";

const fetchData = async () => {
  return await getGQLClient().request<{ returns: Return[] }>(
    gql`
      query fetchData {
        returns {
          id
          gid
          createdAt
          updatedAt
          orderGid
          orderName
          returnIntent {
            returnLineItems {
              fulfillmentLineItemGid
            }
          }
          shop {
            shopName
          }
          shopifyOrder {
            id
            customer {
              email
            }
            shippingAddress {
              formatted
              address1
            }
          }
          ecodropListings {
            id
          }
          lineItems {
            gid
            lineItem {
              name
            }
          }
        }
      }
    `,
  );
};

export const useFetchData = () => {
  return useQuery(["dashboard"], async () => await fetchData(), {
    retry: false,
    refetchOnMount: "always",
    refetchOnWindowFocus: false,
    select: ({ returns }) => {
      const updatedReturns = returns.map(
        ({ updatedAt, createdAt, ...props }) => {
          return {
            updatedAt: parseISO(updatedAt),
            createdAt: parseISO(createdAt),
            ...props,
          };
        },
      );
      const sortedReturns = updatedReturns.sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
      );

      return {
        returns: sortedReturns,
      };
    },
  });
};

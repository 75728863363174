import clsx from "clsx";
import React from "react";
type ButtonProps = {
  size?: "sm" | "md" | "lg" | "full";
  loading?: boolean;
} & React.ComponentProps<"button">;

const sizes = {
  sm: `px-2 py-1 text-sm`,
  md: `px-3 py-2 text-base`,
  lg: `px-5 py-3 text-base`,
  full: `w-full py-3 text-base`,
};

export const Button = React.forwardRef(
  (
    {
      type = "button",
      loading,
      children,
      disabled,
      className,
      size = "md",
      ...props
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          `inline-flex items-center justify-center
        focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 focus:outline-none
        font-medium text-white
        border border-gray-700 rounded
        bg-gray-700 enabled:hover:bg-gray-800
        disabled:bg-gray-500 disabled:text-white disabled:border-gray-500
        transition-all`,
          sizes[size],
          className,
        )}
        disabled={disabled || loading}
        {...props}
      >
        {loading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {children}
      </button>
    );
  },
);

Button.displayName = "Button";

import clsx from "clsx";
import { format, formatDistanceToNow } from "date-fns";
import React from "react";
import { Spinner } from "../../components/spinner";
import { Return } from "../../types/graphql";
import { useFetchData } from "./loader";

const hashStrToInt = (value: string) => {
  let arr = value.split("");
  return arr.reduce(
    (hashCode, currentVal) =>
      (hashCode =
        currentVal.charCodeAt(0) +
        (hashCode << 6) +
        (hashCode << 16) -
        hashCode),
    0,
  );
};

const COLORS = [
  "bg-slate-600",
  "bg-neutral-600",
  "bg-red-600",
  "bg-orange-600",
  "bg-amber-600",
  "bg-indigo-600",
  "bg-violet-600",
  "bg-purple-600",
  "bg-fuchsia-600",
  "bg-pink-600",
  "bg-rose-600",
  "bg-yellow-600",
  "bg-lime-600",
  "bg-emerald-600",
  "bg-teal-600",
  "bg-cyan-600",
  "bg-sky-600",
  "bg-blue-600",
];

const rowPadding = "px-3 py-3";

export const Dashboard = () => {
  const { data, isLoading } = useFetchData();

  if (isLoading || !data) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="w-full h-16 border-b border-300-gray bg-white"></div>
      <div className="w-full h-[calc(100%-64px)] min-h-0 overflow-auto">
        <table
          className="min-w-full w-full h-full min-h-0 border-separate divide-y divide-gray-300 table-fixed"
          style={{ borderSpacing: 0 }}
        >
          <thead className="bg-gray-50">
            <tr>
              <ReturnHeader className="w-36">Shop</ReturnHeader>
              <ReturnHeader className="w-44">Requested</ReturnHeader>
              <ReturnHeader className="w-28">Order Name</ReturnHeader>
              <ReturnHeader className="w-40">Email</ReturnHeader>
              <ReturnHeader className="w-40">Shipping Address</ReturnHeader>
              <ReturnHeader>Item(s)</ReturnHeader>
            </tr>
            {/* <tr>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
            >
              Name
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              Title
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
            >
              Email
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
            >
              Role
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
            >
              <span className="sr-only">Edit</span>
            </th>
          </tr> */}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white w-full">
            {data.returns.map(_return => (
              <ReturnRow key={_return.gid} _return={_return} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const ReturnHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <th
      scope="col"
      className={clsx(
        rowPadding,
        "sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter truncate",
        className,
      )}
    >
      {children}
    </th>
  );
};

const ReturnColumn = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <td
      className={clsx(
        rowPadding,
        "whitespace-nowrap text-sm text-gray-500 truncate",
        className,
      )}
    >
      {children}
    </td>
  );
};

const ReturnRow = ({
  _return: {
    gid,
    shop,
    createdAt,
    updatedAt,
    shopifyOrder,
    orderName,
    lineItems,
  },
}: {
  _return: Return;
}) => {
  const bGcolor = COLORS[hashStrToInt(shop.shopName) % COLORS.length];
  const createdAtFormat = format(createdAt, "M/dd/yyy h:mm a");
  const createdAtRelativeFormat = formatDistanceToNow(createdAt);

  return (
    <tr key={gid} className="w-full">
      <td
        className={clsx(
          "px-5 pxy-3",
          "text-sm font-medium text-gray-900 relative truncate",
        )}
      >
        <div
          className={clsx("w-2 h-full absolute left-0 top-0 bottom-0", bGcolor)}
        ></div>
        {shop.shopName}
      </td>
      <ReturnColumn>
        <div className="text-gray-900">{createdAtFormat}</div>
        <div className="text-gray-500">{createdAtRelativeFormat}</div>
      </ReturnColumn>
      <ReturnColumn>{orderName ? orderName : "N/A"}</ReturnColumn>
      <ReturnColumn>{shopifyOrder?.customer.email}</ReturnColumn>
      <ReturnColumn>{shopifyOrder?.shippingAddress.formatted}</ReturnColumn>
      <ReturnColumn>
        {lineItems.map(li => {
          return <div>{li.lineItem.name}</div>;
        })}
      </ReturnColumn>
    </tr>
  );
};

import BaseGraphiQL from "graphiql";
import "graphiql/graphiql.min.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Combobox, ComboboxChoice } from "../../components/combobox";
import { ShopSelector } from "../../components/shop-selector";
import config from "../../config";
import { clearAuthToken, getAuthToken } from "../../services/auth";
import { schema } from "./schema";

export const GraphiQL = () => {
  const [shopName, setShopName] = useState<string>("");
  const [apiVersion, setApiVersion] = useState<ComboboxChoice>({
    label: "unstable",
    value: "unstable",
  });
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full">
      <div className="h-16 border-b border-gray-200 bg-white flex-none flex items-center px-4">
        <ShopSelector
          onSelect={shop => {
            setShopName(shop);
          }}
        />
        <Combobox
          sizeClassName="ml-2"
          placeholder="API Version"
          value={apiVersion}
          choices={[
            { label: "unstable", value: "unstable" },
            { label: "2022-07", value: "2022-07" },
          ]}
          onChange={value => {
            setApiVersion(value);
          }}
        />
      </div>
      <div className="flex-auto relative">
        {!shopName ? (
          <div className="absolute h-full w-full z-10 bg-opacity-50 bg-gray-400"></div>
        ) : (
          ""
        )}
        <BaseGraphiQL
          schema={schema as any}
          fetcher={async graphQLParams => {
            const data = await fetch(config.BASE_GRAPHQL_PROXY_URL, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAuthToken()}`,
                "X-GraphQL-Proxy-Shop-Name": shopName,
                "X-GraphQL-Proxy-Api-Version": apiVersion?.value || "",
              },
              body: JSON.stringify(graphQLParams),
              credentials: "same-origin",
            });
            if (data.status === 401) {
              clearAuthToken();
              navigate("/login");
            }

            return data.json().catch(e => {
              return data.text();
            });
          }}
        />
      </div>
    </div>
  );
};

import { GraphQLClient } from "graphql-request";
import { Response } from "graphql-request/dist/types";
import config from "../config";
import { clearAuthToken, getAuthToken } from "./auth";
import { redirect } from "react-router-dom";

export const getGQLClient = () => {
  let headers: HeadersInit | undefined = {
    Authorization: `Bearer ${getAuthToken()}`,
  };

  const authMiddleware = ({
    response,
  }: {
    response: Response<unknown> | Error;
  }) => {
    const errors = (response as Response<unknown>)?.errors;
    console.log(errors);

    if (errors && errors.length > 0) {
      const authError = errors.find(
        ({ message }: { message?: string }) =>
          message === "NOT_INTERNAL_AUTHENTICATED",
      );
      if (authError) {
        clearAuthToken();
        redirect("/login");
        return;
      }
    }
  };

  const graphQLClient = new GraphQLClient(config.BASE_GRAPHQL_URL, {
    headers,
    responseMiddleware: authMiddleware as any,
  });

  return graphQLClient;
};

import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { gql } from "graphql-request";
import { getGQLClient } from "../../services/graphql";

export const mutationCleanEcoDropListing = async (
  shopName: string,
  variantGid: string,
) => {
  return await getGQLClient().request<{ cleanEcoDropListing: any }>(
    gql`
      mutation mutationCleanEcoDropListing(
        $shopName: String!
        $variantGid: String!
      ) {
        cleanEcoDropListing(shopName: $shopName, variantGid: $variantGid)
      }
    `,
    { shopName, variantGid },
  );
};

export const useMutationCleanEcoDropListing = (
  options?: UseMutationOptions<{ cleanEcoDropListing: any }, any, {}, {}>,
) => {
  return useMutation(
    ({ shopName, variantGid }: { shopName: string; variantGid: string }) => {
      return mutationCleanEcoDropListing(shopName, variantGid);
    },
    options,
  );
};

export const mutationCreateEcoDropListingFromReturnId = async (
  shopName: string,
  returnId: number,
) => {
  return await getGQLClient().request<{
    createEcoDropListingFromReturnId: any;
  }>(
    gql`
      mutation createEcoDropListingFromReturnId(
        $shopName: String!
        $returnId: Int!
      ) {
        createEcoDropListingFromReturnId(
          shopName: $shopName
          returnId: $returnId
        )
      }
    `,
    { shopName, returnId },
  );
};

export const useMutationCreateEcoDropListingFromReturnId = (
  options?: UseMutationOptions<
    { createEcoDropListingFromReturnId: any },
    any,
    {},
    {}
  >,
) => {
  return useMutation(
    ({ shopName, returnId }: { shopName: string; returnId: number }) => {
      return mutationCreateEcoDropListingFromReturnId(shopName, returnId);
    },
    options,
  );
};

export const mutationCreateEcoDropListingFromVariantGid = async (
  shopName: string,
  variantGid: string,
  returnId: number,
) => {
  return await getGQLClient().request<{
    createEcoDropListingFromVariantGid: any;
  }>(
    gql`
      mutation mutationCreateEcoDropListingFromVariantGid(
        $shopName: String!
        $variantGid: String!
        $returnId: Int!
      ) {
        createEcoDropListingFromVariantGid(
          shopName: $shopName
          variantGid: $variantGid
          returnId: $returnId
        )
      }
    `,
    { shopName, variantGid, returnId },
  );
};

export const useMutationCreateEcoDropListingFromVariantGid = (
  options?: UseMutationOptions<
    { createEcoDropListingFromVariantGid: any },
    any,
    {},
    {}
  >,
) => {
  return useMutation(
    ({
      shopName,
      variantGid,
      returnId,
    }: {
      shopName: string;
      variantGid: string;
      returnId: number;
    }) => {
      return mutationCreateEcoDropListingFromVariantGid(
        shopName,
        variantGid,
        returnId,
      );
    },
    options,
  );
};

export const mutationDecrementVariantInventory = async (
  shopName: string,
  variantGid: string,
) => {
  return await getGQLClient().request<{ decrementVariantInventory: any }>(
    gql`
      mutation mutationDecrementVariantInventory(
        $shopName: String!
        $variantGid: String!
      ) {
        decrementVariantInventory(shopName: $shopName, variantGid: $variantGid)
      }
    `,
    { shopName, variantGid },
  );
};

export const useMutationDecrementVariantInventory = (
  options?: UseMutationOptions<{ decrementVariantInventory: any }, any, {}, {}>,
) => {
  return useMutation(
    ({ shopName, variantGid }: { shopName: string; variantGid: string }) => {
      return mutationDecrementVariantInventory(shopName, variantGid);
    },
    options,
  );
};

export const mutationCreateFulfillmentForEcoDropPurchase = async (
  shopName: string,
  fulfillmentOrderGid: string,
  trackingNumber: string,
  trackingUrl: string,
) => {
  return await getGQLClient().request<{
    createFulfillmentForEcoDropPurchase: any;
  }>(
    gql`
      mutation mutationCreateFulfillmentForEcoDropPurchase(
        $shopName: String!
        $fulfillmentOrderGid: String!
        $trackingNumber: String!
        $trackingUrl: String!
      ) {
        createFulfillmentForEcoDropPurchase(
          fulfillmentInput: {
            shopName: $shopName
            fulfillmentOrderGid: $fulfillmentOrderGid
            trackingNumber: $trackingNumber
            trackingUrl: $trackingUrl
          }
        )
      }
    `,
    { shopName, fulfillmentOrderGid, trackingNumber, trackingUrl },
  );
};

export const useMutationCreateFulfillmentForEcoDropPurchase = (
  options?: UseMutationOptions<
    { createFulfillmentForEcoDropPurchase: any },
    any,
    {},
    {}
  >,
) => {
  return useMutation(
    ({
      shopName,
      fulfillmentOrderGid,
      trackingNumber,
      trackingUrl,
    }: {
      shopName: string;
      fulfillmentOrderGid: string;
      trackingNumber: string;
      trackingUrl: string;
    }) => {
      return mutationCreateFulfillmentForEcoDropPurchase(
        shopName,
        fulfillmentOrderGid,
        trackingNumber,
        trackingUrl,
      );
    },
    options,
  );
};

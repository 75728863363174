import { Tab } from "@headlessui/react";
import {
  GlobeAltIcon,
  GlobeAmericasIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";
import { Webhooks } from "./tabs/Webhooks";
import { Domain } from "./tabs/Domain";

const tabs = [
  { name: "Dashboard", href: "#", icon: HomeIcon },
  { name: "Webhooks", href: "#", icon: GlobeAltIcon },
  { name: "Domain", href: "#", icon: GlobeAmericasIcon },
];

export const Shop = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="px-6 py-2 bg-white h-full w-full min-h-0 flex flex-col">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="border-b border-gray-200 -mb-px flex space-x-8">
          {tabs.map((tab, i) => {
            return (
              <Tab
                className={clsx(
                  i === selectedIndex
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm",
                )}
              >
                <tab.icon
                  className={clsx(
                    i === selectedIndex
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5",
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </Tab>
            );
          })}
        </Tab.List>
        <Tab.Panels className="mt-px overflow-auto flex-auto">
          <Tab.Panel>Content 1</Tab.Panel>
          <Tab.Panel>
            <Webhooks />
          </Tab.Panel>
          <Tab.Panel>
            <Domain />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export const SelectShopPlaceholder = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="border-dashed border-2 border-gray-200 rounded-lg w-[400px] h-[200px] flex flex-col items-center justify-center text-sm font-medium text-gray-900 bg-white">
        <BuildingStorefrontIcon className="text-gray-400 h-16 w-16" />
        <div className="mt-2">Select a Shop</div>
      </div>
    </div>
  );
};

export const AUTH_TOKEN_KEY = "authToken";

export const getAuthToken = () => {
  return sessionStorage.getItem(AUTH_TOKEN_KEY);
};

export const setAuthToken = (authToken: string) => {
  sessionStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

export const clearAuthToken = () => {
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
};

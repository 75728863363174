import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { ShopSelector } from "../../components/shop-selector";
import { CleanListingModal } from "./modals/clean-listing-modal";
import { CreateEcoDropReturnModal } from "./modals/create-ecodrop-return-modal";
import { CreateEcoDropVariantModal } from "./modals/create-ecodrop-variant-modal";
import { CreateFulfillmentPurchaseModal } from "./modals/create-fulfillment-purchase-modal";
import { DecrementVariantModal } from "./modals/decrement-variant-modal";

export const Tools = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<
    | "cleanListing"
    | "createEcoDropReturn"
    | "createEcoDropVariant"
    | "createFulfillmentPurchase"
    | "decrementVariant"
    | null
  >(null);

  return (
    <div className="flex flex-col h-full">
      <Toaster />
      <div className="h-16 border-b border-gray-200 bg-white flex-none flex items-center px-4">
        <div className="text-gray-900 text-lg font-semibold">Tools</div>
        <div className="ml-auto">
          <ShopSelector
            onSelect={value => {
              navigate(`/shops/${value}`);
            }}
          />
        </div>
      </div>
      <div className="flex-auto relative min-h-0 bg-white flex justify-center items-center">
        <div className="w-[600px] border border-gray-200 p-4 rounded-md divide-y">
          <ToolSection
            title="Clean EcoDrop Listing"
            onClick={() => {
              setOpen("cleanListing");
            }}
          />
          <ToolSection
            title="Create EcoDrop from Return Id"
            onClick={() => {
              setOpen("createEcoDropReturn");
            }}
          />
          <ToolSection
            title="Create EcoDrop from Variant Id"
            onClick={() => {
              setOpen("createEcoDropVariant");
            }}
          />
          <ToolSection
            title="Create Fulfillment for EcoDrop Purchase"
            onClick={() => {
              setOpen("createFulfillmentPurchase");
            }}
          />
          <ToolSection
            title="Decrement Variant Inventory"
            onClick={() => {
              setOpen("decrementVariant");
            }}
          />
        </div>
      </div>

      <CleanListingModal
        open={open === "cleanListing"}
        onCloseRequest={() => {
          setOpen(null);
        }}
      />
      <CreateEcoDropReturnModal
        open={open === "createEcoDropReturn"}
        onCloseRequest={() => {
          setOpen(null);
        }}
      />
      <CreateEcoDropVariantModal
        open={open === "createEcoDropVariant"}
        onCloseRequest={() => {
          setOpen(null);
        }}
      />
      <CreateFulfillmentPurchaseModal
        open={open === "createFulfillmentPurchase"}
        onCloseRequest={() => {
          setOpen(null);
        }}
      />
      <DecrementVariantModal
        open={open === "decrementVariant"}
        onCloseRequest={() => {
          setOpen(null);
        }}
      />
    </div>
  );
};

const ToolSection = ({
  title,
  description,
  onClick,
}: {
  title: string;
  description?: string;
  onClick: () => void;
}) => {
  return (
    <div className="flex items-center">
      <div className="flex flex-col">
        <div className="text-lg">{title}</div>
        {description ? (
          <div className="text-sm text-gray-600 mt-1 mb-3">{description}</div>
        ) : null}
      </div>
      <div className="ml-auto">
        <Button
          onClick={() => {
            onClick();
          }}
        >
          Open Tool
        </Button>
      </div>
    </div>
  );
};

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ShopSelector } from "../../components/shop-selector";

export const Shops = () => {
  const navigate = useNavigate();
  const { shopName } = useParams();
  console.log("hi", shopName);

  return (
    <div className="flex flex-col h-full">
      <div className="h-16 border-b border-gray-200 bg-white flex-none flex items-center px-4">
        <div className="text-gray-900 text-lg font-semibold">{shopName}</div>
        <div className="ml-auto">
          <ShopSelector
            onSelect={value => {
              navigate(`/shops/${value}`);
            }}
          />
        </div>
      </div>
      <div className="flex-auto relative min-h-0">
        <Outlet />
      </div>
    </div>
  );
};

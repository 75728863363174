import { useQuery } from "@tanstack/react-query";
import { gql } from "graphql-request";
import { getGQLClient } from "../../../../services/graphql";
import { WebhookConfiguration } from "../../../../types/graphql";

const fetchData = async (shopName?: string) => {
  return await getGQLClient().request<{
    webhookConfiguration: WebhookConfiguration;
  }>(
    gql`
      query fetchData($shopName: String!) {
        webhookConfiguration(shopName: $shopName) {
          configured {
            gid
            topic
            createdAt
            callbackUrl
          }
          missing {
            topic
          }
          misconfigured {
            gid
            topic
            createdAt
            callbackUrl
          }
        }
      }
    `,
    { shopName },
  );
};

export const useFetchData = (shopName?: string) => {
  return useQuery(
    ["webhookConfiguration", shopName],
    async () => await fetchData(shopName),
    {
      enabled: !!shopName,
      retry: false,
      refetchOnMount: false,
    },
  );
};

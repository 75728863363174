import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button } from "../../../components/button";
import { Input, InputGroup } from "../../../components/input";
import Modal from "../../../components/modal";
import { useMutationCreateFulfillmentForEcoDropPurchase } from "../mutations";

// TODO: Rename to either `CreateFulfillmentModal` or `CreateFulfillmentForEcoDropPurchaseModal`,
// because there is no concept of a `FulfillmentPurchase`.
export const CreateFulfillmentPurchaseModal = ({
  open,
  onCloseRequest,
}: {
  open: boolean;
  onCloseRequest: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    shopName: string;
    fulfillmentOrderGid: string;
    trackingNumber: string;
    trackingUrl: string;
  }>();
  const { mutate, isLoading } = useMutationCreateFulfillmentForEcoDropPurchase({
    onSuccess: () => {
      toast.success("Created EcoDrop fulfillment successfully.");
      onCloseRequest();
    },

    onError: () => {
      toast.error("Failed to create EcoDrop fulfillment.");
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const onSubmit = ({
    shopName,
    fulfillmentOrderGid,
    trackingNumber,
    trackingUrl,
  }: {
    shopName: string;
    fulfillmentOrderGid: string;
    trackingNumber: string;
    trackingUrl: string;
  }) => {
    mutate({ shopName, fulfillmentOrderGid, trackingNumber, trackingUrl });
  };

  return (
    <>
      <Modal
        open={open}
        onCloseRequest={() => {
          onCloseRequest();
        }}
        size="max-w-md max-h-screen rounded shadow-md overflow-auto"
      >
        <form className="p-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-lg">
            Create Fulfillment from EcoDrop Purchase
          </div>
          {/* <div className="text-sm text-gray-600 mt-1 mb-4">
            Tool to clean up EcoDrop listings.
          </div> */}
          <div className="flex flex-col space-y-6">
            <InputGroup error={errors?.shopName?.message as string | undefined}>
              <Input
                placeholder="Shop Name"
                inputSize="lg"
                disabled={isLoading}
                {...register("shopName", { required: true })}
              />
            </InputGroup>
            <InputGroup
              error={errors?.fulfillmentOrderGid?.message as string | undefined}
            >
              <Input
                placeholder="Fulfillment Order GID"
                inputSize="lg"
                disabled={isLoading}
                {...register("fulfillmentOrderGid", { required: true })}
              />
            </InputGroup>
            <InputGroup
              error={errors?.trackingNumber?.message as string | undefined}
            >
              <Input
                placeholder="Tracking Number"
                inputSize="lg"
                disabled={isLoading}
                {...register("trackingNumber", { required: true })}
              />
            </InputGroup>
            <InputGroup
              error={errors?.trackingUrl?.message as string | undefined}
            >
              <Input
                placeholder="Tracking Url"
                inputSize="lg"
                disabled={isLoading}
                {...register("trackingUrl", { required: true })}
              />
            </InputGroup>
          </div>
          <Button
            type="submit"
            size="full"
            className="mt-8"
            loading={isLoading}
          >
            Create Fulfillment
          </Button>
        </form>
      </Modal>
    </>
  );
};

import { format, formatDistanceToNow, parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import { useFetchData } from "./loader";

export const Webhooks = () => {
  const { shopName } = useParams<{ shopName: string }>();
  const { data } = useFetchData(shopName);

  return (
    <div>
      {data?.webhookConfiguration?.configured &&
      data?.webhookConfiguration?.configured.length > 0 ? (
        <>
          <div className="my-4 text-lg">Configured</div>
          <table className="min-w-full divide-y divide-gray-300 border-y border-y-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  Topic
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  GID
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  URL
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  Created At
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {data?.webhookConfiguration?.configured.map(webhook => {
                const createdAt = parseISO(webhook.createdAt);
                return (
                  <tr key={webhook.topic}>
                    <td className="whitespace-nowrap px-3 py-2 text-sm">
                      {webhook.topic}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm">
                      {webhook.gid}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm">
                      {webhook.callbackUrl}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm flex flex-col">
                      <div>{format(createdAt, "MM/dd/yyyy h:mm:ss a")}</div>
                      <div className="text-gray-500">
                        {formatDistanceToNow(createdAt)} ago
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : null}

      {data?.webhookConfiguration?.misconfigured &&
      data?.webhookConfiguration?.misconfigured.length > 0 ? (
        <>
          <div className="my-4 text-lg">Misconfigured</div>
          <table className="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  Topic
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  GID
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  URL
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  Created At
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 bg-red-200">
              {data?.webhookConfiguration?.misconfigured.map(webhook => {
                const createdAt = parseISO(webhook.createdAt);
                return (
                  <tr key={webhook.topic}>
                    <td className="whitespace-nowrap px-3 py-2 text-sm">
                      {webhook.topic}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm">
                      {webhook.gid}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm">
                      {webhook.callbackUrl}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm flex flex-col">
                      <div>{format(createdAt, "MM/dd/yyyy h:mm:ss a")}</div>
                      <div className="text-gray-500">
                        {formatDistanceToNow(createdAt)} ago
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : null}

      {data?.webhookConfiguration?.missing &&
      data?.webhookConfiguration?.missing.length > 0 ? (
        <>
          <div className="my-4 text-lg">Missing</div>
          <table className="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-2 text-left text-sm text-gray-900"
                >
                  Topic
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {data?.webhookConfiguration?.missing.map(webhook => {
                return (
                  <tr key={webhook.topic}>
                    <td className="whitespace-nowrap px-3 py-2 text-sm bg-red-200">
                      {webhook.topic}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
};

/* eslint-disable import/no-anonymous-default-export */
let BASE_URL: string = "";
let BASE_GRAPHQL_URL: string = "";
let BASE_GRAPHQL_PROXY_URL: string = "";

if (process.env.REACT_APP_APP_ENV === "prod") {
  BASE_URL = "https://api.fratereturns.com";
} else if (process.env.REACT_APP_APP_ENV === "stag") {
  BASE_URL = "https://api.fratereturns.io";
} else if (process.env.REACT_APP_APP_ENV === "dev") {
  BASE_URL = "https://api.fratereturns.dev";
} else {
  BASE_URL = "http://localhost:8000";
}

BASE_GRAPHQL_URL = `${BASE_URL}/v1/internal/graphql`;
BASE_GRAPHQL_PROXY_URL = `${BASE_URL}/v1/internal/proxy`;

export default { BASE_GRAPHQL_URL, BASE_URL, BASE_GRAPHQL_PROXY_URL };

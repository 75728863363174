import { Outlet } from "react-router-dom";
import { SideNav } from "./components/side-nav";

const AppLayout = () => {
  return (
    <div className="flex w-full bg-gray-100">
      <SideNav />
      <div className="max-h-[100vh] flex-auto min-w-0">
        <Outlet />
      </div>
    </div>
  );
};

export default AppLayout;

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import frateLogo from "../../assets/images/frate_logo.svg";
import { Button } from "../../components/button";
import { Input, InputGroup } from "../../components/input";
import { setAuthToken } from "../../services/auth";
import { useMutationLogin } from "./loader";

type LoginFormValues = { email: string; password: string; mfa: string };

export const Login = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<LoginFormValues>();

  const [loginError, setLoginError] = useState("");

  const { mutate, isLoading } = useMutationLogin({
    onSuccess: ({ login }) => {
      if (login.__typename === "Login") {
        setAuthToken(login.authToken);
        setTimeout(() => {
          navigate("/");
        });
      } else {
        setLoginError("Failed to login. Try again.");
      }
    },
    onError: () => {
      setLoginError("Failed to login. Try again.");
    },
  });

  const onSubmit = ({ email, password, mfa }: LoginFormValues) => {
    mutate({ email, password, mfa });
  };

  return (
    <div className="h-full w-full flex flex-col justify-center items-center sm:px-6 lg:px-8">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 w-full max-w-md">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto mb-4"
            src={frateLogo}
            alt="Frate logo"
          />
        </div>
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {loginError ? (
            <div className="font-semibold text-sm text-red-600">
              {loginError}
            </div>
          ) : null}
          <InputGroup label="Email">
            <Input
              id="email"
              type="email"
              autoComplete="email"
              required
              {...register("email")}
            />
          </InputGroup>

          <InputGroup label="Password">
            <Input
              id="password"
              type="password"
              autoComplete="current-password"
              required
              {...register("password")}
            />
          </InputGroup>
          <InputGroup label="MFA">
            <Input
              id="mfa"
              type="number"
              autoComplete="mfa"
              required
              {...register("mfa")}
            />
          </InputGroup>

          <div className="pb-4">
            <Button size="full" type="submit" loading={isLoading}>
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { gql } from "graphql-request";
import { getGQLClient } from "../../services/graphql";
import { LoginResponse } from "../../types/graphql";

export const mutationLogin = async (
  email: string,
  password: string,
  mfa: string,
) => {
  return await getGQLClient().request<{ login: LoginResponse }>(
    gql`
      mutation mutationLogin(
        $email: String!
        $password: String!
        $mfa: String!
      ) {
        login(input: { email: $email, password: $password, mfa: $mfa }) {
          __typename
          ... on Login {
            email
            authToken
          }
          ... on Errors {
            errors {
              message
            }
          }
        }
      }
    `,
    { email, password, mfa },
  );
};

export const useMutationLogin = (
  options?: UseMutationOptions<{ login: LoginResponse }, any, {}, {}>,
) => {
  return useMutation(
    ({
      email,
      password,
      mfa,
    }: {
      email: string;
      password: string;
      mfa: string;
    }) => {
      return mutationLogin(email, password, mfa);
    },
    options,
  );
};

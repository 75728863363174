import {
  BoltSlashIcon,
  CheckCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/20/solid";
import { format, parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import { useFetchData } from "./loader";

export const Domain = () => {
  const { shopName } = useParams<{ shopName: string }>();
  const { data } = useFetchData(shopName);

  const config = data?.domainConfiguration;

  return (
    <div className="overflow-hidden bg-white">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Domain Configuration
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Vercel domain configuration settings
        </p>
      </div>
      {config ? (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <DataRow label="Name" value={config.name} />
            <DataRow label="Apex Name" value={config.apexName} />
            <DataRow
              label="Verified"
              value={
                config.verified ? (
                  <CheckCircleIcon className="text-green-500 w-6 h-6" />
                ) : (
                  <MinusCircleIcon className="text-reg-500 w-6 h-6" />
                )
              }
            />
            <DataRow label="Git Branch" value={config.gitBranch} />
            <DataRow label="Project Id" value={config.projectId} />
            <DataRow label="Redirect" value={config.redirect} />
            <DataRow
              label="Redirect Status Code"
              value={config.redirectStatusCode}
            />
            <DataRow
              label="Updated At"
              value={format(parseISO(config.updatedAt), "MM/dd/yyyy h:mm:ss a")}
            />
            <DataRow
              label="Created At"
              value={format(parseISO(config.createdAt), "MM/dd/yyyy h:mm:ss a")}
            />
          </dl>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-8">
          <div>
            <BoltSlashIcon className="h-16 w-16 text-gray-300" />
          </div>
          <div className="mt-2 text-sm text-gray-500">
            No vercel domain configured
          </div>
        </div>
      )}
    </div>
  );
};

const DataRow = ({
  label,
  value,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}) => {
  // TODO: Move to be more generic component
  if (value === null) {
    return null;
  }
  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        {value}
      </dd>
    </div>
  );
};

import { Combobox as BaseCombobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";

export type ComboboxChoice = {
  label: string;
  value: string;
};

type ComboboxProps = {
  placeholder?: string;
  choices: ComboboxChoice[];
  sizeClassName?: string;
  value?: ComboboxChoice;
  disabled?: boolean;
  onChange: (choice: ComboboxChoice) => void;
};

export const Combobox = ({
  placeholder,
  choices,
  sizeClassName,
  onChange,
  value,
  disabled,
}: ComboboxProps) => {
  const [query, setQuery] = useState("");

  const filteredChoices =
    query === ""
      ? choices
      : choices.filter(choice =>
          choice.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  return (
    <div className={clsx(sizeClassName ? sizeClassName : "w-72")}>
      <BaseCombobox disabled={disabled} value={value} onChange={onChange}>
        <div className="relative">
          <div
            className={clsx(
              "relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-200 sm:text-sm",
              "focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-300",
            )}
          >
            <BaseCombobox.Input
              disabled={disabled}
              placeholder={placeholder}
              className="w-full border-none py-3 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(choice: ComboboxChoice) => choice?.label}
              onChange={event => setQuery(event.target.value)}
            />
            <BaseCombobox.Button
              disabled={disabled}
              className="absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </BaseCombobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <BaseCombobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
              {filteredChoices.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredChoices.map(choice => (
                  <BaseCombobox.Option
                    key={choice.value}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-gray-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={choice}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {choice.label}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-gray-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </BaseCombobox.Option>
                ))
              )}
            </BaseCombobox.Options>
          </Transition>
        </div>
      </BaseCombobox>
    </div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { gql } from "graphql-request";
import { useState } from "react";
import { getGQLClient } from "../services/graphql";
import { Shop } from "../types/graphql";
import { Combobox, ComboboxChoice } from "./combobox";

const fetchShops = async () => {
  return await getGQLClient().request<{ shops: Shop[] }>(
    gql`
      query fetchShops {
        shops {
          shopName
        }
      }
    `,
  );
};

export const useFetchShops = () => {
  return useQuery(["shops"], async () => await fetchShops(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const ShopSelector = ({
  onSelect,
}: {
  onSelect: (shopName: string) => void;
}) => {
  const [shopName, setShopName] = useState<ComboboxChoice>();
  const { data, isLoading } = useFetchShops();
  const choices =
    data && data?.shops
      ? data.shops.map(({ shopName: sn }) => {
          return { label: sn, value: sn };
        })
      : [];

  return (
    <Combobox
      placeholder="Select shop..."
      disabled={isLoading}
      value={shopName}
      choices={choices}
      onChange={value => {
        setShopName(value);
        onSelect(value.value);
      }}
    />
  );
};

import clsx from "clsx";
import React from "react";

type InputProps = {
  inputSize?: "sm" | "md" | "lg";
  error?: boolean;
} & React.ComponentProps<"input">;

const sizes = {
  sm: "px-3 py-1",
  md: "px-3 py-2",
  lg: "px-3 py-3",
  full: "w-full py-3",
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>( // eslint-disable-line
  ({ error, inputSize = "md", ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={clsx([
          "form-input block w-full border-gray-400 rounded sm:text-sm transition-all",
          error
            ? "border-red-600"
            : "focus:border-gray-700 focus:ring-gray-700",
          sizes[inputSize],
        ])}
        {...props}
      />
    );
  },
);

export const InputGroup = ({
  children,
  label,
  labelProps,
  error,
  ...props
}: {
  children: React.ReactElement;
  label?: React.ReactNode;
  error?: string;
  labelProps?: React.ComponentProps<"label">;
}) => {
  return (
    <div {...props}>
      {label && (
        <label
          className="block text-sm font-medium text-gray-700 mb-1"
          {...labelProps}
        >
          {label}
        </label>
      )}
      {React.cloneElement(children, { error: !!error, ...children.props })}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./components/protected-route";
import { Dashboard } from "./containers/Dashboard";
import { GraphiQL } from "./containers/GraphiQL";
import { Login } from "./containers/Login";
import { SelectShopPlaceholder, Shop } from "./containers/Shop/shop";
import { Shops } from "./containers/Shops";
import { Tools } from "./containers/Tools";
import { AppLayout } from "./layouts/AppLayout";

const queryClient = new QueryClient();

const App = () => (
  <div className="w-full h-full">
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<AppLayout />}>
            <Route element={<Dashboard />} index />
            <Route path="graphiql" element={<GraphiQL />} />
            <Route path="shops" element={<Shops />}>
              <Route index element={<SelectShopPlaceholder />} />
              <Route path=":shopName" element={<Shop />} />
            </Route>
            <Route path="tools" element={<Tools />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Route>
      </Routes>
    </QueryClientProvider>
  </div>
);

export default App;

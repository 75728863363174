import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button } from "../../../components/button";
import { Input, InputGroup } from "../../../components/input";
import Modal from "../../../components/modal";
import { useMutationCreateEcoDropListingFromReturnId } from "../mutations";

// TODO: move to constants file (?)
const MATCH_NUMBER_REGEX = /^[1-9]\d*$/;

export const CreateEcoDropReturnModal = ({
  open,
  onCloseRequest,
}: {
  open: boolean;
  onCloseRequest: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ shopName: string; returnId: number }>();
  const { mutate, isLoading } = useMutationCreateEcoDropListingFromReturnId({
    onSuccess: () => {
      toast.success("Create EcoDrop Listing from return id successfully.");
      onCloseRequest();
    },

    onError: () => {
      toast.error("Failed to create EcoDrop listing from return id.");
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const onSubmit = ({
    shopName,
    returnId,
  }: {
    shopName: string;
    returnId: number;
  }) => {
    mutate({ shopName, returnId });
  };

  return (
    <>
      <Modal
        open={open}
        onCloseRequest={() => {
          onCloseRequest();
        }}
        size="max-w-md max-h-screen rounded shadow-md overflow-auto"
      >
        <form className="p-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-lg">Create EcoDrop Listing from Return Id</div>
          <div className="text-sm text-gray-600 mt-1 mb-4">
            Create EcoDrop listing from return id.
          </div>
          <div className="flex flex-col space-y-6">
            <InputGroup error={errors?.shopName?.message as string | undefined}>
              <Input
                placeholder="Shop Name"
                inputSize="lg"
                disabled={isLoading}
                {...register("shopName", { required: true })}
              />
            </InputGroup>
            <InputGroup error={errors?.returnId?.message as string | undefined}>
              <Input
                placeholder="Return ID"
                inputSize="lg"
                type="number"
                disabled={isLoading}
                {...register("returnId", {
                  required: true,
                  valueAsNumber: true,
                  pattern: {
                    value: MATCH_NUMBER_REGEX,
                    message: "Please enter a number",
                  },
                })}
              />
            </InputGroup>
          </div>
          <Button
            type="submit"
            size="full"
            className="mt-8"
            loading={isLoading}
          >
            Create EcoDrop Listing
          </Button>
        </form>
      </Modal>
    </>
  );
};
